import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { DoctorService } from '../shared/doctor.service';
import { NgForm } from '@angular/forms';
import { RouterModule, Routes} from '@angular/router';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css']
})
export class CodeComponent implements OnInit {
  @Input()
  parentCount: number;

  searchValue: string = "MERCK-";

  @Output() messageEvent = new EventEmitter<string>();

  constructor(public router: Router) { 
    this.messageEvent = new EventEmitter();
  }



  //submitSearch() {
    sendMessage(popinform : NgForm) {
    this.searchValue = popinform.value;
    this.messageEvent.emit(this.searchValue);
    //console.log("this is emit",this.messageEvent.emit(this.searchValue));
    console.log(this.searchValue);
    this.router.navigate(['/doctor']);
  }

  ngOnInit() {
  }

}
