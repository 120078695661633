import { Component, OnInit } from '@angular/core';
import { DoctorService } from '../shared/doctor.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import { CodeComponent } from '../code/code.component';
import { Router } from '@angular/router'; 



@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.css']
})
export class DoctorComponent implements OnInit {
  count: number = 10;

  constructor( public doctorService : DoctorService, public tostr : ToastrService, public http: HttpClient, public router: Router) 
  {
    
  }

  searchValuev:string="MERCK-0000";

  ngOnInit() {
    this.doctorService.getData();
  }

  onSubmit( doctorForm : NgForm ){
    this.doctorService.insertVote(doctorForm.value);
    //this.doctorService.insertVote(this.doctorService.selectedDoctor);
    this.tostr.success('Vote Submitted Successfully');
    this.router.navigate(['/code']);
  }

  receiveMessage($event) {
      this.searchValuev = $event;
  }



 

}
