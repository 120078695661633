import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DoctorComponent } from './doctors/doctor/doctor.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { CodeComponent } from './doctors/code/code.component';

const routes: Routes = [
  { 
    path: 'doctor' , 
    component: DoctorsComponent,
  },
  { 
    path: 'code' , 
    component: CodeComponent,
  }
];
export const routing = RouterModule.forRoot(routes);
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ],
  declarations: []
})
export class AppRoutingModule { }
