import { Component, OnInit } from '@angular/core';
import { DoctorService } from './shared/doctor.service';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.css'],
  providers : [DoctorService]
})
export class DoctorsComponent implements OnInit {

  constructor( public doctorService : DoctorService, public http: HttpClient) { }

  ngOnInit() {
   
  }

  

}
