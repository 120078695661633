import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Doctor } from './doctor.model';
import {HttpClient} from '@angular/common/http';
import { CodeComponent } from '../code/code.component';
import { NgForm } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  codeValue: string = '';
  doctorList : AngularFireList<any>;
  selectedDoctor : Doctor = new Doctor();
  ipAdress:any;
  constructor(public firebase : AngularFireDatabase, public http: HttpClient) {
    this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      console.log('th data', data);
      this.ipAdress = data
    });
   }

  getData()
  {
    this.doctorList = this.firebase.list('doctors');
    return this.doctorList;
  }

  insertVote(doctor : Doctor)
  {
    this.doctorList.push({
      vote : doctor.vote,
      ipadress: this.ipAdress.ip,
      code : doctor.code

    })
  }

  sendCode(doctor : Doctor){
     
  }

  updateVote(doctor : Doctor)
  {
    //modifier vote
  }

  displayValue(popinform : NgForm){
    console.log(popinform.value);
  }
}


